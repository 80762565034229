import { Box, Button, Card, CardContent, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useFormik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { getAuthorizationPmtx } from 'src/services/util.service';
import { setShowLoadingDialog, setStatus } from 'src/store/slices/payments/paymentsSlice';
import { plansSelector, setCurrentPlan } from 'src/store/slices/payments/plansSlice';
import { useAppDispatch } from 'src/store/store';
import { BASE_URL_PMTX } from 'src/utils/endpoint/pmtx.endpoint';
import * as yup from 'yup';
import styles from './PaymentForm.module.scss';

const paymentMethods = [
    { value: "visa", label: "Visa" },
    { value: "mastercard", label: "Mastercard" },
    { value: "amex", label: "American Express" },
    { value: "jcb", label: "JCB" },
];

const customStripeElementStyle = {
    style: {
        base: {
            fontSize: '16px',
            color: '#424770',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#9e2146',
        },
    },
};

export const PaymentForm = () => {
    const dispatch = useAppDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const { currPlan } = useSelector(plansSelector);

    const validationSchema = yup.object({
        paymentMethod: yup.string().required("Payment method is required"),
        email: yup.string().email("Enter a valid email").required("Email is required"),
        cardHolderName: yup.string().required("Card holder name is required"),
    });

    const formik = useFormik({
        initialValues: {
            paymentMethod: "visa",
            email: "",
            cardHolderName: "",
        },
        validationSchema,
        onSubmit: async (values) => {
            dispatch(setShowLoadingDialog(true));
            dispatch(setStatus('loading'));

            if (!stripe || !elements) {
                dispatch(setStatus('error'));
                return;
            }

            const cardElement = elements.getElement(CardNumberElement);
            if (!cardElement) {
                dispatch(setStatus('error'));
                return;
            }

            try {
                const result = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                    billing_details: {
                        name: values.cardHolderName,
                        email: values.email,
                    },
                });

                if (!result.paymentMethod) {
                    throw new Error('Failed to create payment method');
                }

                const paymentData = {
                    email: values.email,
                    nameOnCard: values.cardHolderName,
                    PaymentIntentID: result.paymentMethod.id,
                    priceId: currPlan?.one_time_prices.price_id,
                    ProdId: currPlan?.prod_id,
                    amount: currPlan?.one_time_prices.unit_amount
                };

                const response = await axios.post(
                    BASE_URL_PMTX + "/create-subscription",
                    paymentData,
                    {
                        headers: {
                            Authorization: getAuthorizationPmtx(),
                            'Content-Type': 'application/json'
                        }
                    }
                );

                dispatch(setCurrentPlan(response.data.response));
                dispatch(setStatus('done'));
            } catch (error) {
                dispatch(setStatus('error'));
            }
        },
    });

    return (
        <Grid item xs={12} md={6}>
            <Card className={styles.cardContainer}>
                <CardContent>
                    <form onSubmit={formik.handleSubmit} className={styles.paymentForm}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Payment Detail</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography className={styles.labelSelect}>
                                    Select Payment Method
                                    <Typography component="span" color="error">*</Typography>
                                </Typography>
                                <TextField
                                    select
                                    size="small"
                                    name="paymentMethod"
                                    value={formik.values.paymentMethod}
                                    onChange={formik.handleChange}
                                    error={formik.touched.paymentMethod && Boolean(formik.errors.paymentMethod)}
                                    helperText={formik.touched.paymentMethod && formik.errors.paymentMethod}
                                    fullWidth
                                >
                                    {paymentMethods.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography className={styles.labelSelect}>
                                    Email
                                    <Typography component="span" color="error">*</Typography>
                                </Typography>
                                <TextField
                                    size="small"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    fullWidth
                                    placeholder="example@email.com"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography className={styles.labelSelect}>
                                    Card Holder Name
                                    <Typography component="span" color="error">*</Typography>
                                </Typography>
                                <TextField
                                    size="small"
                                    name="cardHolderName"
                                    value={formik.values.cardHolderName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.cardHolderName && Boolean(formik.errors.cardHolderName)}
                                    helperText={formik.touched.cardHolderName && formik.errors.cardHolderName}
                                    fullWidth
                                    placeholder="Full name"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography className={styles.labelSelect}>
                                            Credit/Debit card number
                                            <Typography component="span" color="error">*</Typography>
                                        </Typography>
                                        <Box sx={{
                                            border: '1px solid #ddd',
                                            padding: '8px',
                                            borderRadius: '4px',
                                            backgroundColor: 'white'
                                        }}>
                                            <CardNumberElement options={customStripeElementStyle} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography className={styles.labelSelect}>
                                            Expiry date
                                            <Typography component="span" color="error">*</Typography>
                                        </Typography>
                                        <Box sx={{
                                            border: '1px solid #ddd',
                                            padding: '8px',
                                            borderRadius: '4px',
                                            backgroundColor: 'white'
                                        }}>
                                            <CardExpiryElement options={customStripeElementStyle} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography className={styles.labelSelect}>
                                            CVC/CVV
                                            <Typography component="span" color="error">*</Typography>
                                        </Typography>
                                        <Box sx={{
                                            border: '1px solid #ddd',
                                            padding: '8px',
                                            borderRadius: '4px',
                                            backgroundColor: 'white'
                                        }}>
                                            <CardCvcElement options={customStripeElementStyle} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    sx={{ textTransform: "none" }}
                                    disabled={!stripe}
                                >
                                    Pay Now
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </Grid >
    );
};
