import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Card, CardContent, Container, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { LoadingDialog } from 'src/components/payments/LoadingDialog';
import { PaymentForm } from 'src/components/payments/PaymentForm';
import { PaymentSummary } from 'src/components/payments/PaymentSummary';
import { UpgradePlan } from 'src/components/payments/UpgradePlan';
import styles from './PaymentsPage.module.scss';
import { useNavigate } from 'react-router-dom';

const PaymentsPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <LoadingDialog />
      <Container maxWidth="xl" sx={{ my: 6 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <Link
                underline="hover"
                color="inherit"
                onClick={() => {
                  navigate('/generate/subscription');
                }}
              >
                Pricing
              </Link>
              <Typography className={styles.headerBreadcrumb}>Payment</Typography>
            </Breadcrumbs>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ width: { sm: 400 } }}>
              <Typography className={styles.header}>
                Start your journey with Essential perks and fast
              </Typography>
            </Box>
          </Grid>

          <PaymentForm />

          <Grid item xs={12} md={6}>
            <Card className={styles.container}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PaymentSummary />
                  </Grid>
                  <Grid item xs={12}>
                    <UpgradePlan />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PaymentsPage;
