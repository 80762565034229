export const pathRoutes = {
  Dashboard: "dashboard",
  MainDashboard: "main-dashboard",
  DataConnection: "database-connection",
  Story: "story",
  AppComponent: "app-component",
  DataReference: "data-reference",
  Specifications: "specifications",
  Prompt: "prompt",

  Generate: "/generate",
  Login: "/login",
  Root: "/loading",
  Workflow: "workflow",
  Subscription: "subscription",
  Payment: "payment",
  Complete: "complete",
};

export const sortType = {
  DESC: "DESC",
  ASC: "ASC",
};
