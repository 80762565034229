//@ts-ignore
export const BASE_URL_PMTX = `${window["env"]["REACT_APP_ZERO_URL"]}/pmtx-api/v1`;

// Subscription
export const CREATE_SUBSCRIPTION_ENDPOINT = `${BASE_URL_PMTX}/create-subscription`;
export const USER_PLAN_SELECTOR_ENDPOINT = `${BASE_URL_PMTX}/create-user-plans-selector`;

//@ts-ignore
export const BASE_URL_PMTX_TOKEN_USED = `${window["env"]["REACT_APP_ZERO_URL"]}/pmtx-ai-token-api/v1`;
export const USER_AI_TOKEN_USED = `${BASE_URL_PMTX_TOKEN_USED}/token-used`;
