import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuthorizationPmtx } from "src/services/util.service";
import { USER_AI_TOKEN_USED } from "src/utils/endpoint/pmtx.endpoint";

export interface UserTokenUsingCheck {
  id: string;
  appIdAi: string;
  appIdAppSpace: string;
  userId: string;
  userGuestId: string;
  userStoriesTokenUsage: number;
  functionalTokenUsage: number;
  screenSpecTokenUsage: number;
  htmlTokenUsage: number;
  totalTokenUsage: number;
  fullTokenUsage: boolean;
}

export interface UserTokenUsingResponse {
  id: string;
  appIdAi: string;
  appIdAppSpace?: string;
  userId: string;
  userGuestId?: string;
  userStoriesTokenUsage: number;
  functionalTokenUsage: number;
  screenSpecTokenUsage: number;
  htmlTokenUsage: number;
}

// สร้าง async action สำหรับดึงข้อมูลจาก API
export const fetchCheckAiTokenByUserID = createAsyncThunk<UserTokenUsingCheck>(
  "aiToken/fetchCheckAiTokenByUserID",
  async () => {
    const response = await axios.get(`${USER_AI_TOKEN_USED}`, {
      headers: {
        Authorization: getAuthorizationPmtx(),
      },
    });

    console.log("fetchCheckAiTokenByUserID : ", response.data.response);

    return response.data.response;
  }
);

// สร้าง async action สำหรับดึงข้อมูลจาก API
export const handlerCreateAiTokenByProjectID = createAsyncThunk<
  UserTokenUsingResponse,
  string
>("aiToken/handlerCreateAiTokenByProjectID", async (project_id: string) => {
  const response = await axios.post(
    `${USER_AI_TOKEN_USED}/${project_id}`,
    {},
    {
      headers: {
        Authorization: getAuthorizationPmtx(),
      },
    }
  );
  return response.data;
});

// สร้าง async action สำหรับดึงข้อมูลจาก API
export const handleUpdateAiTokenByProjectID = createAsyncThunk<
  UserTokenUsingResponse,
  { project_id: string; appIdAppSpace?: string }
>("aiToken/updateAppIdAppSpace", async ({ project_id, appIdAppSpace }) => {
  const response = await axios.patch(
    `${USER_AI_TOKEN_USED}/${project_id}`, // Add 'create' to match backend route
    {},
    {
      headers: {
        Authorization: getAuthorizationPmtx(),
      },
    }
  );
  return response.data;
});
