import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CancelIcon from '@mui/icons-material/Cancel';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleIcon from 'src/assets/svg/check-circle';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/store/store';
import { paymentsSelector } from 'src/store/slices/payments/paymentsSlice';
import { setShowLoadingDialog, setStatus } from 'src/store/slices/payments/paymentsSlice';
import { useNavigate } from 'react-router-dom';
import React from 'react';

export const LoadingDialog = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { status, showLoadingDialog } = useSelector(paymentsSelector);

    return (
        <Dialog
            open={showLoadingDialog}
            maxWidth="xs"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: '16px',
                    overflow: 'hidden',
                    bgcolor: '#fff'
                }
            }}
        >
            <Box sx={{
                height: '100%',
                background: 'linear-gradient(180deg, #f8faff 0%, #ffffff 100%)'
            }}>
                <Box sx={{ p: 4, textAlign: 'center' }}>
                    <Box sx={{
                        width: 90,
                        height: 90,
                        margin: '0 auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        background: '#fff',
                        boxShadow: '0 12px 24px -4px rgba(145, 158, 171, 0.12), 0 0 2px 0 rgba(145, 158, 171, 0.2)'
                    }}>
                        {status === 'loading' && <CircularProgress size={44} />}
                        {status === 'done' && <CheckCircleIcon sx={{ fontSize: 44, color: '#36B37E' }} />}
                        {status === 'error' && <CancelIcon sx={{ fontSize: 44, color: '#FF5630' }} />}
                    </Box>

                    <Box sx={{ mt: 3, mb: 4 }}>
                        <Typography variant="h6" sx={{ fontWeight: 700, mb: 1, color: '#212B36' }}>
                            {status === 'loading' ? 'Processing' : status === 'done' ? 'Success' : 'Failed'}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#637381' }}>
                            {status === 'loading' ? 'Processing your payment...' :
                                status === 'done' ? 'Payment completed successfully' :
                                    'Payment processing failed'}
                        </Typography>
                    </Box>

                    <Box>
                        {status === 'loading' && (
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={() => dispatch(setShowLoadingDialog(false))}
                                sx={{
                                    py: 1.2,
                                    borderRadius: 2,
                                    color: '#212B36',
                                    borderColor: '#919EAB52',
                                    '&:hover': { borderColor: '#212B36' }
                                }}
                            >
                                Cancel
                            </Button>
                        )}

                        {status === 'error' && (
                            <Stack spacing={2}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    startIcon={<RefreshIcon />}
                                    onClick={() => dispatch(setStatus('loading'))}
                                    sx={{
                                        py: 1.2,
                                        borderRadius: 2,
                                        bgcolor: '#FF5630',
                                        '&:hover': { bgcolor: '#B71D18' }
                                    }}
                                >
                                    Try Again
                                </Button>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    onClick={() => dispatch(setShowLoadingDialog(false))}
                                    sx={{
                                        py: 1.2,
                                        borderRadius: 2,
                                        color: '#212B36',
                                        borderColor: '#919EAB52'
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Stack>
                        )}

                        {status === 'done' && (
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => navigate('/generate')}
                                sx={{
                                    py: 1.2,
                                    borderRadius: 2,
                                    bgcolor: '#36B37E',
                                    '&:hover': { bgcolor: '#006C9C' }
                                }}
                            >
                                Back to Generate
                            </Button>
                        )}
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
};
