import React from "react";

const CheckCircleFillIcon = (props) => {
  const { className, style, width, height, fill, opacity } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      style={style}
      className={className}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.323242"
        width="20.9931"
        height="20.9931"
        rx="10.4965"
        fill="#E8EDFB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.3124 8.33136C14.4789 8.50632 14.4721 8.78315 14.2971 8.94969L9.24326 13.7606C9.15695 13.8428 9.04086 13.8861 8.92183 13.8807C8.80279 13.8753 8.69112 13.8216 8.61264 13.7319L6.66883 11.5115C6.50973 11.3297 6.52809 11.0534 6.70983 10.8943C6.89157 10.7352 7.16788 10.7536 7.32698 10.9353L8.97043 12.8127L13.694 8.31613C13.869 8.14959 14.1458 8.15641 14.3124 8.33136Z"
        fill="#1D4ED8"
      />
    </svg>
  );
};

export default CheckCircleFillIcon;
