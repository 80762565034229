import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import PaymentsPage from './PaymentsPage';

// @ts-ignore
let stripeKey = window['env']['REACT_APP_STRIPE_KEY'];

const stripePromise = loadStripe(stripeKey);

export const PaymentsWrapper = () => (
    <Elements stripe={stripePromise}>
        <PaymentsPage />
    </Elements>
);
