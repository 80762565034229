import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Skeleton,
  Typography
} from "@mui/material";
import Stack from "@mui/material/Stack";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CancelCircleFillIcon from "src/assets/svg/cancel-circle-fill";
import CheckCircleFillIcon from "src/assets/svg/check-circle-fill";
import {
  fetchPlanProducts,
  fetchUserPlans,
  plansSelector,
  setCurrentPlan,
  setSelectedPlan,
} from "src/store/slices/payments/plansSlice";
import { useAppDispatch } from "src/store/store";
import { Product } from "src/types/PlanProduct.type";
import { pathRoutes } from "../props/util.props";
import styles from "./SubscriptionPage.module.scss";

type PlanItem = {
  key: string;
  value: string;
};

const ListPlanItems: PlanItem[] = [
  { key: "number_of_nodes", value: "Nodes" },
  { key: "number_of_runs_per_month", value: "Runs" },
  { key: "token_limit_per_month", value: "Token Limit" },
  { key: "number_of_apps", value: "App" },
  { key: "historical_data", value: "Historical Data" },
  { key: "export_to_pdf", value: "Export to PDF" },
  { key: "priority_support", value: "Priority Support" },
  { key: "ai_agent_workflows", value: "AI Agent Workflows" },
  { key: "business_process_workflows", value: "Business Process Workflows" },
  { key: "custom_workflows_apis", value: "Custom Workflows (APIs)" },
  { key: "number_of_users", value: "User" },
];
const ListPlanFreeItems: PlanItem[] = [
  { key: "number_of_nodes", value: "Nodes" },
  { key: "number_of_runs_per_month", value: "Runs" },
  { key: "token_limit_per_month", value: "Token Limit" },
  { key: "number_of_apps", value: "App" },
  { key: "ai_agent_workflows", value: "AI Agent Workflows" },
  { key: "business_process_workflows", value: "Business Process Workflows" },
  { key: "number_of_users", value: "User" },
  { key: "historical_data", value: "Historical Data" },
  { key: "export_to_pdf", value: "Export to PDF" },
  { key: "priority_support", value: "Priority Support" },
  { key: "custom_workflows_apis", value: "Custom Workflows (APIs)" },
];

const SubscriptionPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { plans } = useSelector(plansSelector);
  const { currPlan, loading, selectPlan } = useSelector(plansSelector);

  const onPayment = (currPlan: Product) => {
    dispatch(setCurrentPlan(currPlan));
    navigate(`${pathRoutes.Generate}/${pathRoutes.Payment}`);
  };

  const onSelectedPlan = (plan: Product) => {
    dispatch(setSelectedPlan(plan));
  };


  useEffect(() => {
    dispatch(fetchPlanProducts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUserPlans());
  }, [dispatch]);


  const SubscriptionSkeleton = () => {
    return (
      <Container maxWidth="xl" sx={{ my: 6 }}>
        <Stack spacing={3}>
          <Skeleton variant="text" width={400} height={60} sx={{ mx: "auto" }} />
          <Grid container spacing={3}>
            {/* Free Trial Skeleton */}
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Stack spacing={3} sx={{ pt: 3 }}>
                        <Skeleton variant="text" width={200} height={40} />
                        <Skeleton variant="text" width={250} height={60} />
                        <Skeleton variant="rectangular" width={200} height={40} />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                      <Stack spacing={2}>
                        {[...Array(11)].map((_, index) => (
                          <Stack key={index} direction="row" justifyContent="space-between" alignItems="center">
                            <Skeleton variant="text" width={150} />
                            <Skeleton variant="text" width={50} />
                          </Stack>
                        ))}
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* Subscription Plans Skeleton */}
            {[...Array(4)].map((_, index) => (
              <Grid item key={index} xs={12} sm={6} md={6} lg={3}>
                <Card>
                  <CardContent>
                    <Stack spacing={3} sx={{ pt: 3 }}>
                      <Stack spacing={2}>
                        <Box sx={{ height: 100 }}>
                          <Skeleton variant="text" width={150} height={30} />
                          <Skeleton variant="text" width={200} height={60} />
                        </Box>
                        <Skeleton variant="text" width={120} height={50} />
                        <Skeleton variant="rectangular" width="100%" height={40} />
                      </Stack>
                      <Stack spacing={1}>
                        {[...Array(11)].map((_, idx) => (
                          <Stack key={idx} direction="row" justifyContent="space-between" alignItems="center">
                            <Skeleton variant="text" width={150} />
                            <Skeleton variant="text" width={50} />
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Container>
    );
  };

  if (loading) {
    return <SubscriptionSkeleton />;
  }

  console.log("log: ", plans);






  const renderFreeTrial = () => {
    const freePlan = plans?.response.find((plan) => plan.key === "FREE");
    if (!freePlan) return null;
    return (
      <Grid item key={freePlan.key} xs={12} onClick={() => onSelectedPlan(freePlan)}>
        <Card className={`${styles.toggleCard} ${freePlan.prod_id === selectPlan?.prod_id ? styles.selected : ''}`}>
          <CardContent >
            {freePlan.prod_id === currPlan?.prod_id && (
              <Typography className={styles.currentPlanLabel}>
                Current Plan
              </Typography>
            )}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Stack
                  spacing={3}
                  maxWidth={{ xs: 1, md: 250 }}
                  sx={{ pt: freePlan.prod_id === currPlan?.prod_id ? '48px' : '32px' }}
                >
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h6" pr={1} sx={{ fontSize: "24px" }}>
                      Free Trial
                    </Typography>
                    <span>/ {freePlan.name}</span>
                  </Stack>
                  <Typography variant="caption">{freePlan.description}</Typography>
                  <Button
                    variant="outlined"
                    sx={{ textTransform: "none", fontSize: "15px", fontWeight: 600 }}
                    onClick={() => onPayment(freePlan)}
                    disabled={freePlan.prod_id === currPlan?.prod_id}
                  >
                    {freePlan.prod_id === currPlan?.prod_id ? "Selected" : "Start Free Trial"}
                  </Button>
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={8}
                sx={{ columnCount: { sm: 1, md: 2 }, columnGap: 10 }}
              >
                <Stack spacing={1}>
                  {ListPlanFreeItems.map((item) => (
                    <Stack
                      key={item.key}
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {freePlan.metadata[item.key] === "No" ? (
                          <CancelCircleFillIcon />
                        ) : (
                          <CheckCircleFillIcon />
                        )}
                        <Typography
                          variant="caption"
                          pl={1}
                          className={styles.metadata}
                        >
                          {item.value}
                        </Typography>
                      </Stack>
                      <Typography variant="caption">
                        {freePlan.metadata[item.key]}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const renderSubscriptionPlans = () => {
    const otherPlans = plans?.response.filter((plan) => plan.key !== "FREE");
    if (!otherPlans) return null;

    return otherPlans.map((plan, key) => (
      <Grid
        item
        key={plan.key}
        xs={12}
        sm={6}
        md={6}
        lg={3}
        onClick={() => onSelectedPlan(plan)}
      >
        <Card className={`${styles.toggleCard} ${plan.prod_id === selectPlan?.prod_id ? styles.selected : ''}`}>
          <CardContent>
            {plan.prod_id === currPlan?.prod_id && (
              <Typography className={styles.currentPlanLabel}>
                Current Plan
              </Typography>
            )}
            <Stack
              spacing={3}
              sx={{ pt: plan.prod_id === currPlan?.prod_id ? '48px' : '32px' }}
            >
              <Stack spacing={2}>
                <Box sx={{ height: 100 }}>
                  <Typography variant="h6">{plan.name}</Typography>
                  <Typography variant="caption">{plan.description}</Typography>
                </Box>
                {plan.name === "Enterprise" ? (
                  <Typography variant="h4" pr={1}>
                    Custom
                  </Typography>
                ) : (
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h4" pr={1} sx={{ fontSize: "36px" }}>
                      ${plan.one_time_prices.unit_amount}
                    </Typography>
                    <span>/ Month</span>
                  </Stack>
                )}
                <Button
                  variant="outlined"
                  sx={{ textTransform: "none", fontSize: "15px", fontWeight: 600 }}
                  onClick={() => onPayment(plan)}
                  disabled={plan.prod_id === currPlan?.prod_id}
                >
                  {plan.prod_id === currPlan?.prod_id ? "Selected" :
                    plan.name === "Enterprise" ? "Contact Sales" : "Subscribe Now"}
                </Button>
              </Stack>
              <Stack spacing={1}>
                {ListPlanItems.map((item) => (
                  <Stack
                    key={item.key}
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CheckCircleFillIcon />
                      <Typography
                        variant="caption"
                        pl={1}
                        className={styles.metadata}
                      >
                        {item.value}
                      </Typography>
                    </Stack>
                    <Typography variant="caption">
                      {plan.metadata[item.key]}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    ));
  }


  return (
    <Container maxWidth="xl" sx={{ my: 6 }}>
      {plans?.response && (
        <>
          <Stack spacing={3}>
            <Typography
              variant="h4"
              textAlign="center"
              className={styles.planHeading}
            >
              Choose a plan that's right for you
            </Typography>
            <Grid container spacing={3}>
              {renderFreeTrial()}
              {renderSubscriptionPlans()}
            </Grid>
          </Stack>
        </>
      )}
    </Container>
  );
};

export default SubscriptionPage;