import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

interface PaymentsState {
  status: "loading" | "done" | "error";
  errorMessage: string | null;
  showLoadingDialog: boolean;
  paymentDetails: {
    paymentMethod: string;
    email: string;
    cardHolderName: string;
  };
}

const initialState: PaymentsState = {
  status: "loading",
  errorMessage: null,
  showLoadingDialog: false,
  paymentDetails: {
    paymentMethod: "visa",
    email: "",
    cardHolderName: "",
  },
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<"loading" | "done" | "error">) => {
      state.status = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string | null>) => {
      state.errorMessage = action.payload;
    },
    setShowLoadingDialog: (state, action: PayloadAction<boolean>) => {
      state.showLoadingDialog = action.payload;
    },
    setPaymentDetails: (
      state,
      action: PayloadAction<Partial<PaymentsState["paymentDetails"]>>
    ) => {
      state.paymentDetails = { ...state.paymentDetails, ...action.payload };
    },
  },
});

export const {
  setStatus,
  setErrorMessage,
  setShowLoadingDialog,
  setPaymentDetails,
} = paymentsSlice.actions;
export const paymentsSelector = (store: RootState) => store.paymentsReducer;
export default paymentsSlice.reducer;
