import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import styles from './UpgradePlan.module.scss';
import React from 'react';

const upgradePlans = [
    { value: "$199.00", label: "Advanced" },
    { value: "$539.00", label: "Premium" },
    { value: "Contact with sale", label: "Enterprise" },
];

export const UpgradePlan = () => {
    return (
        <Card className={styles.cardContainer}>
            <CardContent>
                <Typography variant="h6" pb={2}>Upgrade plan for more benefits</Typography>
                <Stack spacing={1}>
                    {upgradePlans.map((item, key) => (
                        <Box key={key} sx={{ border: "1px solid #DCDCDC", borderRadius: "8px", padding: "12px 16px" }}>
                            <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                                <Typography>{item.label}</Typography>
                                <Typography>{item.value}</Typography>
                            </Stack>
                        </Box>
                    ))}
                </Stack>
            </CardContent>
        </Card>
    );
};
