import React from "react";

const CancelCircleFillIcon = (props) => {
  const { className, style, width, height, fill, opacity } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      style={style}
      className={className}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="-0.00683594"
        width="20.9931"
        height="20.9931"
        rx="10.4965"
        fill="#F7F8F9"
      />
      <path
        d="M13.2787 13.2733C13.4495 13.1025 13.4495 12.8256 13.2787 12.6548L11.1142 10.4903L13.2793 8.32523C13.4501 8.15443 13.4501 7.87751 13.2793 7.70671C13.1085 7.53591 12.8316 7.53591 12.6608 7.70671L10.4957 9.87181L8.3306 7.70671C8.1598 7.53591 7.88288 7.53591 7.71208 7.70671C7.54128 7.87751 7.54128 8.15443 7.71208 8.32523L9.87718 10.4903L7.71268 12.6548C7.54188 12.8256 7.54188 13.1025 7.71268 13.2733C7.88347 13.4441 8.16039 13.4441 8.33119 13.2733L10.4957 11.1088L12.6602 13.2733C12.831 13.4441 13.1079 13.4441 13.2787 13.2733Z"
        fill="#191D23"
      />
    </svg>
  );
};

export default CancelCircleFillIcon;
