import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Box
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { plansSelector, fetchPlanProducts } from "src/store/slices/payments/plansSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/store/store";
import { pathRoutes } from "src/page/props/util.props";

interface DialogAiTokenExcessiveProps {
  open: boolean;
  onClose: () => void;
}

const DialogAiTokenExcessive: React.FC<DialogAiTokenExcessiveProps> = ({
  open,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currPlan } = useSelector(plansSelector);

  const handleUpgrade = () => {
    dispatch(fetchPlanProducts());
    navigate(`${pathRoutes.Generate}/${pathRoutes.Subscription}`)
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '16px',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        }
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Typography variant="h5" fontWeight="600" color="primary">
          Token Limit Reached
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ py: 2 }}>
          <DialogContentText sx={{
            color: 'text.primary',
            fontSize: '1rem',
            lineHeight: 1.6
          }}>
            You have reached the token limit for your current plan
            <Box component="span" sx={{ fontWeight: 600, mx: 1 }}>
              {currPlan?.name}
            </Box>
            To continue using AI features, please upgrade to a plan with more tokens.
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3, gap: 2 }}>
        <Button
          onClick={onClose}
          variant="outlined"
          size="large"
          sx={{
            borderRadius: '8px',
            textTransform: 'none',
            fontSize: '16px',
            px: 4
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleUpgrade}
          variant="contained"
          size="large"
          sx={{
            borderRadius: '8px',
            textTransform: 'none',
            fontSize: '16px',
            px: 4,
            boxShadow: 2
          }}
        >
          View Plans & Upgrade
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogAiTokenExcessive;
