import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import plansReducer from "./slices/payments/plansSlice";
import paymentsReducer from "./slices/payments/paymentsSlice";
import stripeReducer from "./slices/payments/stripeSlice";
import userReducer from "./slices/userSlice";
import aiTokenReducer from "./slices/ai-token/aiTokenSlice";

const reducer = {
  plansReducer,
  paymentsReducer,
  stripeReducer,
  userReducer,
  aiTokenReducer,
};

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV === "development",
});

// export type of root state from reducers
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
