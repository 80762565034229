import axios from "axios";
import i18n from "i18next";
import { checkEmpty, getAuthorization, getAuthorizationPmtx } from "./util.service";
import { jwtDecode } from "jwt-decode";

export const AuthKeys = {
  token: "token",
  expiresDate: "expiresDate",
  tokenType: "tokenType",
  _key_user: "_key_user",
  openID: "openID",
  user: "user",
};
export const APIEndpoint = {
  Login: "oauth/token",
  PmtxLongin : "pmtx/login"
};

// export function saveToken({
//     token,
//     tokenType,
//     expiresDate,
//     remember,
// }: any) {
//     if (remember) {
//         localStorage.setItem(AuthKeys.token, token);
//         localStorage.setItem(AuthKeys.tokenType, tokenType);
//         localStorage.setItem(AuthKeys.expiresDate, expiresDate);
//     } else {
//         sessionStorage.setItem(AuthKeys.token, token);
//         sessionStorage.setItem(AuthKeys.tokenType, tokenType);
//         sessionStorage.setItem(AuthKeys.expiresDate, expiresDate);
//     }
//     localStorage.setItem(AuthKeys.token, token);
//     localStorage.setItem(AuthKeys.tokenType, tokenType);
//     localStorage.setItem(AuthKeys.expiresDate, expiresDate);

//     axios
//         .get(window.__RUNTIME_CONFIG__.REACT_APP_PLAYME_URL + APIEndpoint.Me, {
//             headers: {
//                 "Content-Type": "application/json",
//                 Authorization: getAuthorization(),
//             },
//         })
//         .then((res) => {
//             // let prefix = "1234567890";
//             // console.log("res : ", res.data);

//             let userObj = JSON.stringify(res.data);
//             // let prefixEn = btoa(prefix);
//             // let userObEn = btoa(userObj);
//             var userObEn = btoa(unescape(encodeURIComponent(userObj)))
//             let data = userObEn;
//             if (remember) {
//                 localStorage.setItem(AuthKeys._key_use, data);
//             } else {
//                 sessionStorage.setItem(AuthKeys._key_use, data);
//             }
//             localStorage.setItem(AuthKeys._key_use, data);
//             localStorage.setItem("meto", userObj);
//             console.log("lang:", res.data.language.toLowerCase());

//             if (checkEmpty(localStorage.getItem("lang"))) {
//                 localStorage.setItem("lang", res.data?.language.toLowerCase());
//                 i18n.changeLanguage(localStorage.getItem("lang")!, () => {
//                     console.log(
//                         `change language to ${localStorage.getItem("lang")!} success`
//                     );
//                 });
//             }

//             localStorage.setItem("chatFontSize", res.data?.fontSize.toLowerCase());
//             document.documentElement.setAttribute(
//                 "chat-size",
//                 res.data?.fontSize.toLowerCase()
//             );
//         });
// }

// export const checkLogin = (history: any) => {
//     let token = getToken();
//     // console.log("token : ",token);
//     if (checkEmpty(token)) {
//         return false;
//     } else {
//         return true;
//     }
// };
// export const checkAuth = () => {
//     // let token: any = getToken();
//     // console.log("token : ",token);
//     // if (checkEmpty(token)) {
//     //   return false;
//     // } else {
//     //   localStorage.setItem(AuthKeys.token, token);
//     //   sessionStorage.setItem(AuthKeys.token, token);
//     //   return true;
//     // }
//     // const me = await api.get(UserAPIEndpoint.Me);
//     return new Promise((resolve, reject) => {
//         axios
//             .get(window..REACT_APP_PLAYME_URL + APIEndpoint.Me, {
//                 headers: {
//                     "Content-Type": "application/json",
//                     Authorization: getAuthorization(),
//                 },
//                 timeout: 5000
//             })
//             .then((res) => {
//                 console.log("checkAuth", res);
//                 if (res.status != 200) {
//                     resolve(false);
//                 } else {
//                     resolve(true);
//                 }
//             })
//             .catch((e) => {
//                 console.log("checkAuth err=>", e);
//                 resolve(false);
//             });
//     });
// };

// export function getToken() {
//     return (
//         localStorage.getItem(AuthKeys.token) ||
//         sessionStorage.getItem(AuthKeys.token)
//     );
// }

export function login({
  username,
  password,
}: {
  username: string;
  password: string;
}) {
  //   let hash = getSetCokieLogin();
  // if (checkEmpty(fcmToken)) {
  // fcmToken = hash;
  // }

  // @ts-ignore
  let basicAuth = window["env"]["REACT_APP_BASIC_AUTH"];
  
  var encodedString = btoa(basicAuth);

  const params = new URLSearchParams();
  params.append("grant_type", "password");
  params.append("username", username);
  params.append("password", password);
  params.append('basic_auth', encodedString);

  return axios.post(
    // window["env"]["REACT_APP_IAM2"] + APIEndpoint.Login,
    // @ts-ignore
    window["env"]["REACT_APP_ZERO_URL"] + "/" + APIEndpoint.PmtxLongin,
    params,
    // fcmToken: fcmToken, //localStorage.getItem("device_token"),
    //   deviceID: hash,

    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
}

export async function resetToken(reload: any) {
  try {
  } catch (e) {
    console.log("[resetToken]", e);
  } finally {
    localStorage.removeItem(AuthKeys.openID);

    // localStorage.removeItem(AuthKeys.expiresDate);
    // localStorage.removeItem(AuthKeys.token);
    // localStorage.removeItem(AuthKeys.tokenType);
    // sessionStorage.removeItem(AuthKeys.expiresDate);
    // sessionStorage.removeItem(AuthKeys.token);
    // sessionStorage.removeItem(AuthKeys.tokenType);

    // localStorage.removeItem("device_token");

    // localStorage.removeItem(AuthKeys._key_use);
    // sessionStorage.removeItem(AuthKeys._key_use);

    if (reload) {
      window.location.reload();
    }
  }
}

export const checkExpired = () => {
  const auth: any = localStorage.getItem(AuthKeys.openID);
  const parsedAuth = JSON.parse(auth);
  const decoded = jwtDecode(parsedAuth?.access_token);
  //   console.log("Decoded JWT:", decoded);

  const expirationTime = decoded.exp ? decoded.exp * 1000 : 0;
  const isExpired = expirationTime < Date.now();
  return isExpired;
};
