import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  fetchCheckAiTokenByUserID,
  handlerCreateAiTokenByProjectID,
  handleUpdateAiTokenByProjectID,
  UserTokenUsingCheck,
  UserTokenUsingResponse,
} from "src/store/actions/ai-token/aiTokenActions";

type TokenState = {
  aiTokenId?: string;
  currUserTokenUsingCheck?: UserTokenUsingCheck;
  currTokenUsing?: UserTokenUsingResponse;
  showTokenDialog: boolean;
  loading: boolean;
  error: boolean;
  errMessage?: string;
};

const initialValues: TokenState = {
  showTokenDialog: false,
  loading: false,
  error: false,
};

const aiTokenSlice = createSlice({
  name: "aiToken",
  initialState: initialValues,
  reducers: {
    setShowTokenDialog: (state) => {
      state.showTokenDialog = true;
    },
    setCloseTokenDialog: (state) => {
      state.showTokenDialog = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCheckAiTokenByUserID.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchCheckAiTokenByUserID.fulfilled, (state, action) => {
      state.loading = false;
      state.currUserTokenUsingCheck = action.payload;
      state.showTokenDialog = action.payload.fullTokenUsage ?? false;
    });
    builder.addCase(fetchCheckAiTokenByUserID.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errMessage = action.error.message;
    });

    builder.addCase(handlerCreateAiTokenByProjectID.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(
      handlerCreateAiTokenByProjectID.fulfilled,
      (state, action) => {
        state.loading = false;
        state.currTokenUsing = action.payload;
        state.aiTokenId = action.payload.id;
      }
    );
    builder.addCase(
      handlerCreateAiTokenByProjectID.rejected,
      (state, action) => {
        state.loading = false;
        state.error = true;
        state.errMessage = action.error.message;
      }
    );

    builder.addCase(handleUpdateAiTokenByProjectID.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(
      handleUpdateAiTokenByProjectID.fulfilled,
      (state, action) => {
        state.loading = false;
        state.currTokenUsing = action.payload;
      }
    );
    builder.addCase(
      handleUpdateAiTokenByProjectID.rejected,
      (state, action) => {
        state.loading = false;
        state.error = true;
        state.errMessage = action.error.message;
      }
    );
  },
});

export const { setShowTokenDialog, setCloseTokenDialog } = aiTokenSlice.actions;
export const aiTokenSelector = (store: RootState) => store.aiTokenReducer;
export default aiTokenSlice.reducer;
