import { Button, notification, Popover, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useContext, useEffect, useState } from "react";
import styles from "../generate/Generate.module.scss";

import { useNavigate } from "react-router-dom";
import LogoPromptX from "src/assets/png/PromptX_white.png";
import ThreeStarIcon from "src/assets/svg/threeStarIcon";
import * as Auth from "src/services/auth.service";
import { v4 as uuidv4 } from "uuid";
import { pathRoutes } from "../props/util.props";

import { Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { fetchCheckAiTokenByUserID, handlerCreateAiTokenByProjectID } from "src/store/actions/ai-token/aiTokenActions";
import { aiTokenSelector, setCloseTokenDialog } from "src/store/slices/ai-token/aiTokenSlice";
import { useAppDispatch } from "src/store/store";
import { GenerateContext } from "../context/Generate.context";
import { SpeechToText } from "./components/SpeechToText";
import ModalWarning from "./modal/modalWarning";
import DialogAiTokenExcessive from "./specification/user-story/components/DialogAiTokenExcessive";

type GenerateProps = {};

// Custom Hook: useGenerate
export const useGenerate = () => {
  const navigate = useNavigate();
  const { setMessageData } = useContext(GenerateContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null | number>(null);

  const auth = localStorage.getItem(Auth.AuthKeys.openID);

  const generateData = async (url: string, data: any) => {
    setLoading(true);
    setError(null);

    let token: any;

    if (auth) {
      token = JSON.parse(auth);
    }

    try {
      // ส่งข้อมูลไปยัง API โดยใช้ fetch
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.access_token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        setError(response.status);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setMessageData(response);
    } catch (err: any) {
      setError(err);
      console.log(err);

      notification.error({
        message: "Error",
        description: "There was an error generating data. Please try again.",
        placement: "topRight",
      });
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, generateData, setLoading };
};

// function Generate() {
//     return (
//         <GenerateContextProvider>
//             <GenerateContainer />
//         </GenerateContextProvider>
//     );
// }

const userType = {
  USER: "user",
  GUEST: "guest",
};

function Generate({ }: GenerateProps) {
  const auth = localStorage.getItem(Auth.AuthKeys.openID);
  const keyUser = localStorage.getItem(Auth.AuthKeys._key_user);

  const navigate = useNavigate();
  const [valueText, setValueText] = useState("");
  const { messageData, projectName, setProjectName, uuidApp, setUuidApp } =
    useContext(GenerateContext);
  const { loading, error, generateData, setLoading } = useGenerate();
  const [errorMessage, setErrorMessage] = useState<string | null | number>(
    null
  );
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);

  const [openID, setOpenID] = useState<string | null>(null);
  const [typeUser, setTypeUser] = useState<string | null>(null);
  const [user, setUser] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [placeholder, setPlaceholder] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const fullPlaceholder = "Describe your application";
  const dispatch = useAppDispatch();
  const { currUserTokenUsingCheck, showTokenDialog } = useSelector(aiTokenSelector);


  useEffect(() => {
    clearLocalStorageOnload();

    const openIDFromStorage = localStorage.getItem(Auth.AuthKeys.openID);
    const userTypeFromStorage = localStorage.getItem(Auth.AuthKeys._key_user);
    const userFromStorage = localStorage.getItem(Auth.AuthKeys.user);

    setOpenID(openIDFromStorage);
    setTypeUser(userTypeFromStorage);
    setUser(userFromStorage);
    setProjectName("");

    // Add token usage check
    dispatch(fetchCheckAiTokenByUserID());

    const uuid = uuidv4();
    setUuidApp(uuid);

    let index = 0;
    const typingInterval = setInterval(() => {
      if (index < fullPlaceholder.length) {
        const charToAdd = fullPlaceholder[index];
        setPlaceholder((prev) => prev + charToAdd);
        index++;
      } else {
        clearInterval(typingInterval);
      }
    }, 100);

    return () => clearInterval(typingInterval);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // ปรับเวลาได้ตามต้องการ

    return () => clearTimeout(timer);
  }, []);

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement> | string) {
    const newValue = typeof e === "string" ? e : e.target.value;
    setValueText(newValue);
    console.log("setValueText", newValue);
  }

  useEffect(() => {
    console.log("messageData >>>>", messageData);
  }, [messageData]);


  const mutation = useMutation({
    mutationFn: Auth.login,
    onSuccess: (data) => {
      console.log("User created successfully:", data);
      // You can handle additional logic here, like updating cache or state
    },
    onError: (error) => {
      console.error("Error creating user:", error);
    },
  });

  function clearLocalStorageOnload() {
    //หากมาจาก guest login จะไม่ทำการ clearLocalStorage
    let isGuestLogin = localStorage.getItem("isGuestLogin");
    if (!isGuestLogin) {
      localStorage.removeItem("projectName");
      localStorage.removeItem("promptId");
      localStorage.removeItem("appMessages");
    }
  }

  async function onClickGenerate() {
    console.log("valueText", valueText);
    console.log("uuidApp ", uuidApp);

    localStorage.setItem("promptId", JSON.stringify(uuidApp));
    setLoading(true);

    // Check token usage first
    await dispatch(fetchCheckAiTokenByUserID());

    if (!currUserTokenUsingCheck?.fullTokenUsage) {
      try {
        if (!user || !openID || !typeUser || typeUser === userType.GUEST) {
          // @ts-ignore
          let username = window["env"]["REACT_APP_USERNAME"];
          // @ts-ignore
          let password = window["env"]["REACT_APP_PASSWORD"];

          const { data }: any = await mutation.mutateAsync({
            username,
            password,
          });
          if (data && data.access_token) {
            localStorage.setItem(Auth.AuthKeys.openID, JSON.stringify(data));
            localStorage.setItem(Auth.AuthKeys._key_user, userType.GUEST);
          }
        }
      } catch (error) {
        console.error("Login failed:", error);
      } finally {
        setLoading(false);
      }

      try {
        // @ts-ignore
        let ping = `${window["env"]["REACT_APP_PING_AI"]}`;
        await generateData(
          // @ts-ignore
          `${window["env"]["REACT_APP_GENERATE_URL"]}/create/user_stories/${uuidApp}`,
          {
            requirement: valueText,
            n_pingpong: ping,
          }
        ).then((_) => {
          console.log("user_stories : ", uuidApp);
          dispatch(handlerCreateAiTokenByProjectID(uuidApp));
        });
      } catch (error) {
        console.error("Error during data generation:", error);
      }
    }
    setLoading(false);
  }
  function ClickLogin() {
    console.log("ClickLogin");
    return navigate(pathRoutes.Login);
  }

  function ClickLogOut() {
    localStorage.removeItem(Auth.AuthKeys.openID);
    localStorage.removeItem(Auth.AuthKeys._key_user);
    localStorage.removeItem(Auth.AuthKeys.user);

    setOpenID(null);
    setTypeUser(null);
    setUser(null);

    handleCloseModal();
  }

  const handleClickLogOut = () => {
    // แสดง modal
    localStorage.removeItem(Auth.AuthKeys.openID);
    localStorage.removeItem(Auth.AuthKeys._key_user);
    localStorage.removeItem(Auth.AuthKeys.user);
    setIsModalVisible(true);
    navigate(pathRoutes.Generate);
    window.location.reload();
  };

  const handleCloseModal = () => {
    // ปิด modal
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (error) {
      console.log("Error detected ", error);
    } else if (!loading && !error && messageData) {
      navigate(`/${pathRoutes.Generate}/${pathRoutes.Specifications}`);
    }
  }, [error, loading, messageData, navigate]);

  return (
    <>
      {!typeUser || typeUser === userType.GUEST ? (
        <div className={styles.accountHeader} onClick={ClickLogin}>
          {" "}
          <Button type="primary">Sign in</Button>
        </div>
      ) : (
        <div className={styles.accountHeader}>
          <Popover
            content={
              <div className={styles.userMenu}>
                <div className={styles.menuItems}>
                  <div
                    className={styles.menuItem}
                    onClick={() =>
                      navigate(
                        `${pathRoutes.Generate}/${pathRoutes.Subscription}`
                      )
                    }
                  >
                    <span>Subscription</span>
                  </div>
                  <div
                    className={styles.menuItem}
                    onClick={() => handleClickLogOut()}
                  >
                    <span>Logout</span>
                  </div>
                </div>
              </div>
            }
            trigger="hover"
            placement="bottomRight"
          >
            <div className={styles.welcomeButton}>Welcome</div>
          </Popover>
        </div>
      )}

      {isModalVisible && (
        <ModalWarning
          onClose={handleCloseModal}
          open={isModalVisible}
          onLogOut={ClickLogOut}
        />
      )}
      <div className={styles.generateContainer}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.imgLogo}>
              <img
                src={LogoPromptX}
                alt={LogoPromptX}
                className={styles.logo}
              />
            </div>
            <div
              style={{ display: "contents" }}
              className={`${styles.label} ${isVisible ? styles.visible : ""}`}
            >
              Describe the app you want to build
              <div>
                <strong>Today.</strong>
              </div>
            </div>
          </div>

          {loading && <Spin />}
          <div className={styles.inputButtonContainer}>
            <TextArea
              rows={8}
              placeholder={placeholder || ""}
              className={styles.textArea}
              value={valueText}
              onChange={handleChange}
            />
            <Stack
              flexDirection="row"
              width="100%"
              justifyContent="flex-end"
              alignContent="center"
            >
              <SpeechToText onChange={handleChange} />
              <Button
                type="primary"
                size="middle"
                icon={<ThreeStarIcon />}
                className={styles.generateButton}
                onClick={onClickGenerate}
                disabled={loading || !valueText}
              >
                <span className={styles.buttonLabel}>Generate</span>
              </Button>
            </Stack>
          </div>
        </div>
      </div>
      <DialogAiTokenExcessive
        open={showTokenDialog}
        onClose={() => dispatch(setCloseTokenDialog())}
      />
    </>
  );

}
export default Generate;
