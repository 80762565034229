import React, { useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { FaMicrophone } from "react-icons/fa";
import styled from "styled-components";
import { Popover, Radio, RadioChangeEvent, Space } from "antd";

interface SpeechToTextProps {
  onChange: (value: string) => void;
}

const MicButton = styled.button<{ isListening: boolean }>`
  background: rgba(14, 82, 209, 0.2);
  border: none;
  cursor: pointer;
  padding: 7px 7px 2px 7px;
  margin-top: 16px;
  margin-right: 8px;
  border-radius: 50%;
  transition: all 0.3s ease;

  svg {
    width: 18px;
    height: 18px;
    color: ${(props) => (props.isListening ? "#113F8C" : "#808080")};
    animation: ${(props) =>
      props.isListening ? "pulse 1.5s infinite" : "none"};
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  &:hover {
    background: #113F8C;
    
    svg {
      color: #fff;
    }
  }
    
  }
`;

export const SpeechToText: React.FC<SpeechToTextProps> = ({ onChange }) => {
  const [isListening, setIsListening] = useState(false);
  const [language, setLanguage] = useState("en-US");
  const {
    transcript,
    interimTranscript,
    browserSupportsSpeechRecognition,
    resetTranscript,
  } = useSpeechRecognition();

  useEffect(() => {
    handleTranscriptUpdate();
  }, [transcript, interimTranscript]);

  const handleTranscriptUpdate = () => {
    const currentText = transcript || interimTranscript;
    onChange(currentText);
  };

  const handleLanguageChange = (e: RadioChangeEvent) => {
    setLanguage(e.target.value);
    if (isListening) {
      SpeechRecognition.stopListening();
      setIsListening(false);
    }
  };

  const handleToggleListen = () => {
    if (isListening) {
      SpeechRecognition.stopListening();
      setIsListening(false);
    } else {
      SpeechRecognition.startListening({
        continuous: true,
        language: language,
      });
      setIsListening(true);
      resetTranscript();
    }
  };

  if (!browserSupportsSpeechRecognition) {
    return <div>Browser doesn't support speech recognition.</div>;
  }

  const languageContent = (
    <div>
      <Radio.Group value={language} onChange={handleLanguageChange}>
        <Space direction="vertical">
          <Radio value="en-US">English</Radio>
          <Radio value="th-TH">Thai</Radio>
        </Space>
      </Radio.Group>
    </div>
  );

  return (
    <Popover
      content={languageContent}
      title="Select Language"
      trigger="hover"
      placement="left"
    >
      <MicButton
        isListening={isListening}
        onClick={handleToggleListen}
        title={isListening ? "Stop listening" : "Start listening"}
      >
        <FaMicrophone />
      </MicButton>
    </Popover>
  );
};
